
/*#06090E*/
/*#152A3D*/
/*#294F74*/
/*#3A668B*/
/*#437FB1*/
body{
    background: #152A3D;
    color: white;
}
.photo{
    background:  url("img/fon.jpg") no-repeat;
    background-position: center center;
    background-size: cover;
    height: 650px;
    width: 400px;
    position: absolute;
    top: 5%;
    left: 50%;
    margin-left: -450px;
    box-shadow: 0 0 15px black;
    border-radius: 15px;

}
.main{
    background: #152A3D url("img/f3.svg") no-repeat;
    background-size: cover;
    background-position: right;
    height: 650px;
    position: relative;
    margin-bottom: 70px;
    box-shadow: 0 0 10px black;

}
.mainc{
    display: flex;
    justify-content: end;
    align-items: center;
    height: 100%;
}
.mainText{
    width: 60%;
    color: white;
    text-align: right;
    margin-right: 50px;


}
.mainText h1{
    font-size: 4rem;
}
.mainText p{
    font-size: 2rem;
}
.osnPage{
    min-height: 90vh;
}
.typRasclad{
    border: 1px dashed white;
    padding: 10px;
    border-radius: 10px;
}
.cardV{
    border-radius: 5px;
    background: black url("img/sova.svg") no-repeat;
    background-size: auto 80%;
    height: 200px;
    width: 150px;
    margin: 5px;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.4);
    position: relative;
    border-radius: 20px;

}
.cardV span{
    position: absolute;
    color: white;
    bottom:15px;
    right: 15px;
    border-radius: 50%;
    border:1px solid white;
    text-align: center;
    width: 50px;
    height: 50px;
    font-size: 2rem;
}

.ck-editor__editable_inline {
    min-height: 400px;

}

@media  (max-width: 768px) {
    .cardV{
        border-radius: 5px;
        background: black url("img/sova.svg") no-repeat;
        background-size: auto 80%;
        height: 100px;
        width: 80px;
        margin: 5px;

        position: relative;


    }
    .cardV span{
        position: absolute;
        color: white;
        bottom:5px;
        right: 5px;
        border-radius: 50%;
        border:1px solid white;
        text-align: center;
        width: 30px;
        height: 30px;
        font-size: 1.2rem;
    }
    .photo{
        position: relative;
        height: 450px;
        width: 80%;

        top: 5%;
        left: 10%;
        margin-left: 0;
        box-shadow: 0 0 15px black;
        border-radius: 15px;
    }
    .main{

        height: auto;

        margin-bottom: 0px;

    }
    .mainText{
        width: 100%;
        color: white;
        text-align: center;
        margin-right: auto;
    }
    .mainText h1{
        font-size: 2rem;
    }
    .mainText p{
        font-size: 1rem;
    }
}


